<template>
  <v-card
    color="element"
    :min-width="width"
    :width="width"
    @click="$emit('card-click')"
    class="rounded-lg"
    :disabled="comingSoon"
  >
    <v-img
      v-if="imageUrl"
      :height="250"
      :src="imageUrl"
    ></v-img>
    <div v-else-if="loading" class="element loading-image" />
    <DefaultImage
      v-else
      :height="250"
      :blur="comingSoon"
    />
    <v-card-text class="defaultText--text">
      <div v-if="comingSoon" class="text-subtitle font-weight-regular coming-soon d-flex flex-column justify-end">
        <div class="pb-8">
          {{ $t('marketplace.productCard.comingSoon') }}
        </div>
        <div class="d-flex my-2">
          <v-chip small color="backgroundDark">
            {{ $t('newDeals.productInfo.chip') }}
          </v-chip>
          <v-chip class="ml-2" small color="backgroundDark">
            {{ $t(`assetSubtype.${product.assetSubType}`) }}
          </v-chip>
        </div>
      </div>
      <div v-else>
        <div v-if="['Published'].includes(product.assetStatus)">
          <div class="d-flex">
            <div class="secondaryText--text">
              {{ $t('marketplace.productCard.preCountdown') }}
            </div>
            <div class="ml-2 chip-container">
              <v-chip class="d-flex justify-center" small color="primary">
                <span class="black--text ml-1">{{ countdown.days }}</span>
                <span class="grey--text caption">d</span>
                <span class="black--text ml-1">{{ countdown.hours }}</span>
                <span class="grey--text caption">h</span>
                <span class="black--text ml-1">{{ countdown.minutes }}</span>
                <span class="grey--text caption">m</span>
                <span class="black--text ml-1">{{ countdown.seconds }}</span>
                <span class="grey--text caption">s</span>
              </v-chip>
            </div>
          </div>
        </div>
        <ProgressBar
          v-if="['Offered', 'Financed'].includes(product.assetStatus)"
          :percentage="product.fundingPercentage * 100"
        />
        <div class="mt-2 text-subtitle font-weight-regular">{{ product.title }}</div>
        <div class="mt-2 text-subtitle font-weight-medium">
          {{ $n(product.subscriptionInformation.emissionVolume, 'integerCurrency') }}
        </div>
        <div class="d-flex mt-2">
          <v-chip small color="backgroundDark">
            {{ $t('newDeals.productInfo.chip') }}
          </v-chip>
          <v-chip class="ml-2" small color="backgroundDark">
            {{ $t(`assetSubtype.${product.assetSubType}`) }}
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'ProductCard',
  components: {
    DefaultImage: lazyLoad('components/Assets/DefaultImage'),
    ProgressBar: lazyLoad('components/Shared/ProgressBar'),
  },
  props: {
    product: {
      type: Object,
    },
    width: {
      type: Number,
      default: 350,
    },
    comingSoon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: null,
      loading: true,
      now: null,
    };
  },
  async created() {
    if (this.comingSoon) {
      this.loading = false;
      return;
    }
    try {
      const images = await this.$store.dispatch('assets/getImages', {
        category: 'Cover',
        assetId: this.product.id,
      });
      if (images.length > 0) {
        this.imageUrl = images[0].url;
      }
    } catch (error) {
      this.$notify.error(error.message);
    }
    this.loading = false;
    setInterval(() => {
      this.now = new Date().getTime();
    }, 1000);
  },
  computed: {
    countdown() {
      const { startDate } = this.product.subscriptionInformation;
      const start = new Date(startDate).getTime();
      const timeleft = start - this.now;
      const daysRaw = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      const hoursRaw = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesRaw = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const secondsRaw = Math.floor((timeleft % (1000 * 60)) / 1000);
      const days = daysRaw >= 0 ? daysRaw : 0;
      const hours = hoursRaw >= 0 ? hoursRaw : 0;
      const minutes = minutesRaw >= 0 ? minutesRaw : 0;
      const seconds = secondsRaw >= 0 ? secondsRaw : 0;
      return {
        days, hours, minutes, seconds,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.align-right {
  text-align: right;
}
.no-border-radius {
  border-radius: 0 !important;
}
.no-border-top-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.loading-image {
  height: 250px;
}
.blur-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.text-subtitle {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.009375em !important;
}
.chip-container {
  width: 120px !important;
}
.coming-soon {
  height: 136px;
}
</style>
